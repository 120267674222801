
import React, {useEffect, useState} from 'react';
import {MotionAnimate} from "react-motion-animate";
import CardComponents from "../components/card";
import axios from "axios";
import { TypeAnimation } from 'react-type-animation';
import AnimatedText from 'react-animated-text-content';
import 'animate.css';
import Typewriter from 'typewriter-effect';

function MainPage(props) {

    const  {REACT_APP_PROD_API_URL} = process.env

    const [data, setData] = useState();
    const [titles, setTitles] = useState();

    const [load, setLoad] = useState(true);

    const fetchData = () => {

        fetch(`${REACT_APP_PROD_API_URL}/texts`)
            .then(response => response.json())
            .then(dataTexts => setTitles(dataTexts))

        fetch(`${REACT_APP_PROD_API_URL}/partys`)
            .then(response => response.json())
            .then(dataParty => setData(dataParty))

        setLoad(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (<div className={''}>

        {load && <div className={'d-flex align-items-center justify-content-center vh-100'}>
            <h1>
                Завантаження...
            </h1>
        </div>}

            <div className="container text-center p-md-5 p-1">

                <div className="titles col-md-10 col-12 m-auto d-flex align-items-center justify-content-center" style={{height: "70vh"}}>

                    <div className="d-flex flex-column p-1 css-typing justify-content-around align-items-center pb-5">

                        <p className={'m-0'}>
                            {titles?.length > 0 && titles[0]?.text}
                        </p>
                        <p className={'m-0'}>
                            {titles?.length > 0 && titles[1]?.text}
                        </p>
                        <p className={'m-0'}>
                            {titles?.length > 0 && titles[2]?.text}
                        </p>
                        <p className={'m-0'}>
                            {titles?.length > 0 && <text className={'d-flex justify-content-around align-items-center'} dangerouslySetInnerHTML={{ __html: titles[3].text }} />}
                        </p>
                        <p className={'m-0'}>
                            {titles?.length > 0 && titles[4]?.text}
                        </p>
                    </div>

                </div>


                <div className="sticky-top mb-5 bottom-0 pb-5">

                        <div className="d-flex flex-wrap bg-white border-top border-bottom p-md-3 p-1 animate__animated animate__delay-2s animate__bounceInUp">

                        <div className="col-md-4 col-6">
                            <div className="p-md-3 p-1">
                                <a href="#party-in-law" className={'nav-link main-link'}>
                                    <h3 className={'text-primary'}>Партії в законі</h3>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 col-6">
                            <div className="p-md-3 p-1">
                                <a href="#empty-party" className={'nav-link main-link'}>
                                    <h3  className={'text-warning'}>Немає Партії</h3>
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="p-md-3 p-1">
                                <a href="#transhi-on-party" className={'nav-link main-link'}>
                                    <h3  className={'text-danger'}>Транші на Партії</h3>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>


                <div className="pt-5">
                    {data && data.map((item, index) =>
                            // <MotionAnimate animation='fadeInUp'
                            //                                           reset={true}
                            //                                           distance={200}
                            //                                           delay={1}
                            //                                           speed={1}>
                        <CardComponents key={index} item={item} />
                    // </MotionAnimate>
                    )}
                </div>

            </div>

        <div className="sticky-bottom">
            <div className="email">
                <a href="mailto:sale@2025ukr.party" target={'_blank'} className={'btn btn-dark rounded-0 p-3'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-envelope" viewBox="0 0 16 16">
                        <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                    </svg> sale@2025ukr.party</a>
            </div>
        </div>
    </div>);
}

export default MainPage;
